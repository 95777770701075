<template>
  <div :id="'levantamento_card' + item.id" class="vs-con-loading__container">
    <vx-card :class="cardClass">
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col
              v-if="item.nome_fantasia && item.id"
              class="pb-1"
              vs-w="10"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
            >
              <vs-button
                color="dark"
                class="py-0 px-0 my-0 pr-1"
                size="small"
                type="line"
                @click="openModalCliente(item.id)"
              >
                <span class="nome-cliente">{{
                  item.nome_fantasia ? item.nome_fantasia : "..."
                }}</span>
              </vs-button>
            </vs-col>
            <vs-col
              vs-w="2"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
              v-if="item.data_competencia === hoje"
            >
              <Badge :text="'HOJE'" color="danger"></Badge>
            </vs-col>
            <div @click="iniciarLevantamentoModal = true">
              <vs-col
                v-if="item.enderecoNome"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm">
                  Endereco:
                  <span class="font-semibold">{{ item.enderecoNome }}</span>
                </span>
              </vs-col>

              <vs-col
                v-if="item.segmento"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm">
                  Segmento:
                  <span class="font-semibold">{{ item.segmento }}</span>
                </span>
              </vs-col>
              <vs-col
                v-if="item.data_competencia"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm">
                  Data:
                  <span class="font-semibold"
                    >{{ item.data_competencia }}
                    {{ item.horario ? item.horario : " " }}</span
                  >
                </span>
              </vs-col>
              <vs-col
                vs-w="12"
                v-if="item.colaborador"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm">
                  Colaborador:
                  <span class="font-semibold">{{ item.colaborador }}</span>
                </span>
              </vs-col>
            </div>
          </div>
        </div>
      </template>
    </vx-card>
    <vs-popup
      class
      :title="'Levantamento de ' + item.nome_fantasia"
      :active.sync="iniciarLevantamentoModal"
    >
      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <vx-card
            class="overflow-hidden"
            :class="
              item.executado == 1
                ? 'prospectar'
                : item.executado == 2
                ? 'buttonDanger'
                : 'buttonDisabled'
            "
            @click="
              item.executado == 2 && !blockButton
                ? openModalNaoExec()
                : item.executado == 1
                ? redirectBlank(item)
                : ''
            "
          >
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="p-5"
                  >
                    <span>
                      <vs-icon
                        class="img"
                        icon="remove_red_eye"
                        size="30px"
                        color="success"
                      ></vs-icon>
                    </span>
                  </vs-col>
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="pb-5"
                  >
                    <span style="font-size: 20px; color: inherit"
                      >Visualizar</span
                    >
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
          <!-- </a> -->
        </vs-col>
        <vs-col
          vs-w="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <vx-card
            :class="
              $atividades.permissoes.includes(2)
                ? 'preencher overflow-hidden'
                : 'sem-autorizacao overflow-hidden'
            "
            @click="$atividades.permissoes.includes(2) ? preencher() : ''"
          >
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="p-5"
                  >
                    <span>
                      <vs-icon
                        icon="edit"
                        size="30px"
                        color="primary"
                        class="img"
                      ></vs-icon>
                    </span>
                  </vs-col>
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="pb-5"
                  >
                    <span style="font-size: 20px">Elaborar Proposta</span>
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </vs-row>
    </vs-popup>
    <component
      v-bind:is="showModalLev"
      :key="reRenderModalLev"
      :lev="item"
      @close="iniciarLevantamentoModal = false"
    ></component>
    <component
      v-bind:is="componentCliente"
      :key="reRenderComponentCliente"
      :id_cliente="selectedCliente"
    />
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import ModalEditarCliente from "./../../cliente/editarSemBotao.vue"
import router from "./../../../../router.js"
import levNaoExecWithoutButton from "../../agenda-servicos/lev/components/levNaoExecWithoutButton"

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    frasesDeclinio: {},
    relatorioUrl: ""
  },
  data () {
    return {
      cliente: {},
      blockButton: false,
      reRenderModalLev: 0,
      showModalLev: "",
      turnos: [
        { id: null, nome: "Nenhum" },
        { id: 1, nome: "Manha" },
        { id: 2, nome: "Almoco" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" },
      ],
      cardClass: "prospeccao-card overflow-hidden",
      optionEmpresa: [
        { text: "EMOPS", value: 4 },
        { text: "E-CONTROL", value: 1 },
      ],
      selectedEmpresa: 1,
      iniciarLevantamentoModal: false,
      hoje: this.$formartData.dataFiltro(),
      selectedCliente: null,
      reRenderComponentCliente: 0,
      componentCliente: ""
    }
  },
  methods: {
    async redirectBlank (os) {
      const link = `${this.relatorioUrl}/?os=${os.id_lev}&lev=1`
      var a = document.createElement('a')
      a.target = "_blank"
      a.href = link
      a.click()
    },
    async openModalNaoExec () {
      if (this.blockButton) {
        return
      }
      this.blockButton = true
      this.showModalLev = "levNaoExecWithoutButton"
      this.reRenderModalLev++
    },
    async preencher () {
      await this.close()

      await router.push(
        `/prospeccao?wizardTab=4&id_contrato=${this.item.id_contrato}&id_lev=${this.item.id_lev}`
      )
    },
    async openModalCliente (id_cliente) {
      this.selectedCliente = id_cliente
      this.componentCliente = "ModalEditarCliente"
      this.reRenderComponentCliente++
    },
    async close () {
      this.iniciarLevantamentoModal = await false
    },
  },
  components: { ...components, ModalEditarCliente, levNaoExecWithoutButton },
};
</script>

<style lang="scss" scoped>
.prospeccao-card {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.prospectar {
  transition-duration: 50ms;
  color: #626262;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.buttonDanger {
  transition-duration: 50ms;
  background-color: #c0564b;
  color: white;
  .img {
    color: white !important;
  }
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #c0564b;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.buttonDisabled {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
.preencher {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #ffca00;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.con-vs-chip {
  min-height: 15px;
  min-width: 20px !important;
  font-size: 0.8rem;
}
.sem-autorizacao {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
.nome-cliente {
  font-weight: 600;
  font-size: 14px;
}
</style>
