<template>
  <div>
    <vs-popup
      :title="'LEVANTAMENTO N° ' + lev.id"
      :active.sync="levModalNaoExec"
    >
      <vs-row
        vs-type="flex"
        vs-align="center"
        id="remarcar"
        class="vs-con-loading__container"
      >
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-3"
        >
          <vs-image style="width: 430px" :src="levNaoExec.foto" />
        </vs-col>
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-3"
        >
          <div class="w-full p-0">
            <h4>{{ levNaoExec.frase }} ({{ levNaoExec.data }})</h4>
            <span>{{ levNaoExec.obs }}</span>
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
export default {
  props: {
    lev: {
      required: true,
    },
  },
  data() {
    return {
      levModalNaoExec: false,
      levNaoExec: {},
    };
  },
  methods: {
    async openModal() {
      this.$emit("close");
      this.levModalNaoExec = true;
      await this.getLevNaoExecData();
    },
    async getLevNaoExecData() {
      try {
        this.levNaoExec = await this.$http.post("/getLevNaoExecData", {
          id: this.lev.id,
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async close() {
      this.levModalNaoExec = false;
    },
  },
  async mounted() {
    await this.openModal();
  },
};
</script>
<style lang="scss" scoped>
.os {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.osConf {
  background-color: #f44336;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sem-autorizacao {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
</style>
