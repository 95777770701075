<template>
  <div :id="'contrato_card' + item.id" class="vs-con-loading__container">
    <vx-card :class="cardClass">
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col
              v-if="item.nome_fantasia && item.id_cliente"
              class="pb-1"
              vs-w="10"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
            >
              <vs-button
                color="dark"
                class="py-0 px-0 my-0 pr-1"
                size="small"
                type="line"
                @click="openModalCliente(item.id_cliente)"
              >
                <span class="nome-cliente">{{
                  item.nome_fantasia ? item.nome_fantasia : "..."
                }}</span>
              </vs-button>
            </vs-col>
            <vs-col
              vs-w="2"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
            ></vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
            >
              <a
                :href="'/contrato?id=' + item.id"
                style="color: inherit; text-decoration: inherit"
              >
                <div class="w-full">
                  <vs-col
                    v-if="item.estagio"
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <span class="item-description truncate text-sm">
                      <span class="font-semibold">
                        {{ item.estagio }}
                        {{
                          item.renovacao > 0 && item.id_relacional > 0
                            ? " de Renovação "
                            : ""
                        }}
                        N°{{ item.id }}
                      </span>
                    </span>
                  </vs-col>
                  <vs-col
                    v-if="item.valor > 0"
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <span class="item-description truncate text-sm">
                      Valor
                      <span class="font-semibold">
                        {{ $currency(item.valor) }}</span
                      >
                    </span>
                  </vs-col>
                  <vs-col
                    v-if="item.valor > 0"
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <span class="item-description truncate text-sm">
                      Valor da hora:
                      <span class="font-semibold">R$ 200,00</span>
                    </span>
                  </vs-col>
                  <vs-col
                    v-if="item.data_vencimento"
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <span class="item-description truncate text-sm">
                      Data de Vencimento:
                      <span class="font-semibold">
                        {{ item.data_vencimento }}
                      </span>
                    </span>
                  </vs-col>
                  <vs-col
                    vs-w="12"
                    v-if="item.colaborador"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <span class="item-description truncate text-sm">
                      Colaborador:
                      <span class="font-semibold">{{ item.colaborador }}</span>
                    </span>
                  </vs-col>
                </div>
              </a>
            </vs-col>
          </div>
        </div>
      </template>
    </vx-card>
    <component
      v-bind:is="componentCliente"
      :key="reRenderComponentCliente"
      :id_cliente="selectedCliente"
    />
  </div>
</template>
<script>
import ModalEditarCliente from "./../../cliente/editarSemBotao.vue"
import components from "@/components/default/exports.js"

export default {
  props: {
    item: {
      required: true
    }
  },
  data () {
    return {
      cliente: {},
      cardClass: "proposta-card overflow-hidden",
      selectedCliente: {},
      reRenderComponentCliente: 0,
      componentCliente: ""
    }
  },
  methods: {
    async openModalCliente (id_cliente) {
      this.selectedCliente = id_cliente
      this.componentCliente = "ModalEditarCliente"
      this.reRenderComponentCliente++
    }
  },
  components: { ...components, ModalEditarCliente }
};
</script>

<style lang="scss" scoped>
.proposta-card {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.nome-cliente {
  font-weight: 600;
  font-size: 14px;
}
</style>
