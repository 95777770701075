<template>
<div>
  <vx-card style="background-color: #000a12 ;">
    <vs-row vs-type="flex">
    <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
        <div><h6 style="color: white">PROPOSTAS: {{propostas.length}}</h6></div>
      </vs-col>
    </vs-row>
  </vx-card>
  <VuePerfectScrollbar class="scroll-proposta-lista"  :settings="settings" style="background-color: #ebebeb;">
  <div id="proposta_lista" class="vs-con-loading__container">
    <div v-if="!propostas.length > 0">
    <div class="con-colors mt-10 mb-20">
        <ul>
          <span class="px-3 pt-2" style="color: grey !important; font-weight: 1000 !important"><vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon></span>
        </ul>
      </div>
    </div>
    <vs-list
      v-for="(data, idx) in propostas"
      v-bind:key="idx"
    >
    <propostaCard :item="data" @update="getPropostas()">
      <template slot="action-buttons">
            <div class="flex flex-wrap">
            </div>
          </template>
    </propostaCard>

    </vs-list>
    </div>
  </VuePerfectScrollbar>
</div>
</template>
<script>
import propostaCard from"./propostaCard.vue";
import components from "@/components/default/exports.js";

export default {
  props: {
    filtro:{
    }
    },
  data() {
    return {
      settings             : {
        maxScrollbarLength : 60,
        wheelSpeed: 0.8
      },
      novoClienteModal: false,
      propostas: {},
      segmentos: {}
    };
  },
  methods: {
    async getPropostas() {
      await this.$vs.loading({ container: '#proposta_lista',scale: 0.6});
      try {
        this.filtro.id_colaborador = localStorage.getItem('id')
        this.propostas = await this.$http.post(`listaProposta`, this.filtro);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close('#proposta_lista > .con-vs-loading');
      }
    },
  },
  components:{ ...components,propostaCard},
  async mounted(){
      await this.getPropostas();
  }
  };
</script>

<style>
.scroll-proposta-lista {
  height: 70vh !important;
}
</style>
