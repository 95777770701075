<template>
  <div class="w-full py-0 my-0 mt-0">
    <div>
      <vs-row
        vs-type="flex"
        class="mb-4"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
      >
        <vs-col vs-w="4" vs-justify="flex-start" vs-align="flex-start">
          <div class="w-full">
            <vs-row vs-w="12" vs-justify="flex-start" vs-align="flex-start">
              <vs-col vs-w="12" vs-justify="flex-start" vs-align="flex-start">
                <h4 style="color: #b4aa99">
                  {{ colaborador.nome }}
                  <lembreteModal :key="reRenderLembreteModal"></lembreteModal>
                </h4>
              </vs-col>
            </vs-row>
          </div>
        </vs-col>
        <vs-col
          size="small"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
          vs-w="8"
        >
          <vs-input
            size="small"
            class="mx-2"
            type="date"
            label="De"
            v-model="filtro.dataDe"
          ></vs-input>
          <vs-input
            class="mx-2"
            type="date"
            size="small"
            label="Até"
            v-model="filtro.dataAte"
          ></vs-input>
          <div>
            <el-select
              filterable
              clearable
              size="small"
              placeholder="Segmento"
              class="mx-1"
              v-model="filtro.segmento"
            >
              <el-option
                v-for="segmento in segmentos"
                :value="segmento.id"
                :label="segmento.nome"
                :key="segmento.id"
              >
              </el-option>
            </el-select>
          </div>
          <div v-if="$atividades.permissoes.includes(17)">
            <el-select
              filterable
              size="small"
              class="mx-1"
              clearable
              placeholder="Vendedor"
              v-model="filtro.vendedor"
            >
              <el-option
                v-for="vendedor in vendedores"
                :value="vendedor.id"
                :label="vendedor.nome"
                :key="vendedor.id"
              ></el-option>
            </el-select>
          </div>
          <el-button
            size="small"
            type="primary"
            class="mx-1"
            plain
            @click="filtrar()"
            >FILTRAR</el-button
          >
        </vs-col>
      </vs-row>
    </div>
    <vs-row class="py-0 my-0" :key="reRender">
      <vs-col vs-w="3" class="px-1">
        <prospeccaoLista
          @updateLembretes="updateLembretes"
          :filtro="filtro"
        ></prospeccaoLista>
      </vs-col>
      <vs-col vs-w="3" class="px-1">
        <levantamentoLista :filtro="filtro"></levantamentoLista>
      </vs-col>
      <vs-col vs-w="3" class="px-1">
        <propostaLista :filtro="filtro"></propostaLista>
      </vs-col>
      <vs-col vs-w="3" class="px-1">
        <contratoLista :filtro="filtro"></contratoLista>
      </vs-col>
    </vs-row>
    <!-- <component v-bind:is="lembreteModal" :key="reRenderLembreteModal" /> -->
  </div>
</template>

<script>
import components from "@/components/default/exports.js"
import prospeccaoLista from "./components/prospeccaoLista.vue"
import propostaLista from "./components/propostaLista.vue"
import contratoLista from "./components/contratoLista.vue"
import levantamentoLista from "./components/levantamentoLista.vue"
import lembreteModal from "./components/lembreteModal.vue"

export default {
  data () {
    return {
      segmentos: {},
      filtro: {
        dataHoje: this.$formartData.dataFiltro(),
        dataDe: null,
        dataAte: null,
        segmento: null,
        vendedor: parseInt(window.localStorage.getItem("id")) || null,
        show_all: this.$atividades.permissoes.includes(17),
      },
      lembreteModal: "",
      reRenderLembreteModal: 0,
      vendedores: {},
      reRender: 1,
      colaborador: { nome: "Carregando... " },
    }
  },
  methods: {
    async filtrar () {
      this.reRender = (await this.reRender) + 1
    },
    async getSegmentos () {
      try {
        this.segmentos = await this.$http.get("segmento")
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getColaborador () {
      try {
        this.colaborador = await this.$http.get(
          "colaborador/" + localStorage.getItem("id")
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async updateLembretes () {
      this.reRenderLembreteModal++
    },
    async getVendedores () {
      try {
        this.vendedores = await this.$http.get(`colaborador`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async openModalLembretes () {
      this.lembreteModal = "lembreteModal"
      this.reRenderLembreteModal += 1
    },
  },
  async mounted () {
    await this.getColaborador()
    await this.getSegmentos()
    await this.getVendedores()
  },
  components: {
    ...components,
    prospeccaoLista,
    levantamentoLista,
    propostaLista,
    contratoLista,
    lembreteModal,
  },
};
</script>

<style>
.no-pad {
  padding: 0px !important;
}
.scroll-area-pros {
  height: 70vh !important;
}
.con-vs-chip {
  min-height: 15px;
  min-width: 20px !important;
  font-size: 0.8rem;
}
</style>
