<template>
  <div>
    <div
      @click="
        $atividades.permissoes.includes(2)
          ? (errors.clear(), (novoClienteModal = true), (buttonStatus = false))
          : ''
      "
      class="pb-5"
    >
      <vs-col
        vs-w="10"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
      >
        <span>ADICIONAR LEAD</span>
      </vs-col>
      <vs-col
        vs-w="2"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        class=""
      >
        <span><vs-icon icon="add_to_photos"></vs-icon></span>
      </vs-col>
    </div>
    <vs-popup class="" title="Cliente" :active.sync="novoClienteModal">
      <vs-row vs-type="flex" vs-align="center">
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <div style="display: inline-block" class="w-full">
            <vs-input
              name="nome"
              v-validate="'required'"
              label="Nome fantasia"
              :disabled="!clienteSelected ? false : true"
              v-model="clienteNovo.nome_fantasia"
              class="inputx w-full px-3"
            />
            <span class="ml-3 text-danger text-sm" v-show="errors.has('nome')">
              {{ $validators.empty }}</span
            >
          </div>
        </vs-col>
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <div style="display: inline-block" class="w-full">
            <vs-input
              name="razao_social"
              v-validate="clienteNovo.tipo_pessoa == 2 ? 'required' : ''"
              label="Razão Social"
              :disabled="!clienteSelected ? false : true"
              v-model="clienteNovo.razao_social"
              class="inputx w-full px-3"
            />
            <span
              class="ml-3 text-danger text-sm"
              v-show="errors.has('razao_social')"
            >
              {{ $validators.empty }}</span
            >
          </div>
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" vs-justify="center" vs-align="center">
          <vs-input
            label="Email"
            name="email"
            :disabled="!clienteSelected ? false : true"
            v-model="clienteNovo.email"
            class="inputx w-full px-3"
          />
        </vs-col>
        <vs-col
          vs-w="5"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="mt-1"
        >
          <div style="display: inline-block" class="w-full">
            <vs-input
              name="telefone"
              label="Telefone"
              v-validate="'required'"
              type="tel"
              :disabled="!clienteSelected ? false : true"
              v-mask="'(##)#########'"
              v-model="clienteNovo.telefone"
              class="inputx w-full px-3"
            />
            <span
              class="ml-3 text-danger text-sm"
              v-show="errors.has('telefone')"
            >
              {{ $validators.empty }}</span
            >
          </div>
        </vs-col>
        <vs-col
          vs-w="6"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          class="mt-1"
        >
          <div class="w-full px-3">
            <label style="color: black; font-size: 0.85rem">Segmento</label
            ><br />
            <el-select
              filterable
              clearable
              placeholder="Selecione..."
              name="segmento"
              style="width: 100%"
              :disabled="!clienteSelected ? false : true"
              v-validate="'required'"
              v-model="clienteNovo.id_segmento"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="segmento in segmentos"
                class="select-info"
                :value="segmento.id"
                :label="segmento.nome"
                :key="segmento.id"
              >
              </el-option>
            </el-select>
            <span class="text-danger text-sm" v-show="errors.has('segmento')">
              {{ $validators.empty }}</span
            >
          </div>
        </vs-col>
        <vs-col
          vs-w="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="mt-1"
        >
          <vs-input
            label="Data marcada"
            name="data"
            type="date"
            :disabled="!clienteSelected ? false : true"
            v-model="clienteNovo.data_proximo_contato"
            class="inputx w-full px-3"
          />
        </vs-col>
        <vs-col
          vs-w="6"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <vs-input
            label="CPF/CNPJ"
            name="cpf_cnpj"
            v-model="clienteNovo.cpf_cnpj"
            :disabled="!clienteSelected ? false : true"
            type="tel"
            masked="true"
            v-mask="
              clienteNovo.tipo_pessoa == 2
                ? '##.###.###/####-##'
                : '###.###.###-##'
            "
            class="inputx w-full px-3"
          />
        </vs-col>
        <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center">
          <b-form-group>
            <b-form-radio-group
              id="tipo"
              v-model="clienteNovo.tipo_pessoa"
              :options="optionTipo"
              :disabled="!clienteSelected ? false : true"
              size="lg"
              buttons
              button-variant
              name="tipo"
              class="pt-10"
            ></b-form-radio-group>
          </b-form-group>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
        <vs-divider class="m-0 p-0" border-style="dotted">
          <div class="w-full">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              >Tipo de Interação</vs-col
            >
          </div>
        </vs-divider>
      </vs-row>
      <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
        <div class="w-full">
          <selectTipoLigacao
            :opcao="clienteNovo.id_tipo_contato"
            @select-interaction="selectInteraction"
          ></selectTipoLigacao>
        </div>
      </vs-col>
      <vs-row vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
        <vs-divider class="mt-6" border-style="dotted">
          <div class="w-full">
            <vs-col
              vs-w="11.5"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              ><span class="mr-3">Buscar Cliente</span></vs-col
            ><vs-col
              vs-w="0.5"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vx-tooltip color="danger" text="Nome, Email, CPF ou Razão Soc.">
                <vs-icon
                  style="cursor: pointer"
                  size="15px"
                  class="m-0 p-0"
                  icon="info"
                /> </vx-tooltip
            ></vs-col></div></vs-divider
      ></vs-row>

      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          class="mt-1"
        >
          <div class="w-full px-3 mb-3">
            <el-select
              filterable
              clearable
              placeholder="Pesquise..."
              remote
              class="w-full"
              loading-text="Buscando..."
              :loading="loading"
              :remote-method="findCliente"
              v-model="clienteSelected"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="cliente in clientes"
                class="select-info"
                :value="cliente.id"
                :label="cliente.nome_fantasia"
                :key="cliente.id"
              >
              </el-option>
            </el-select>
          </div>
        </vs-col>
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
          class=""
        >
          <div v-if="!clienteSelected">
            <vs-button
              type="relief"
              color="success"
              class="mt-5"
              :disabled="buttonStatus"
              @click="submitForm()"
              >Adicionar</vs-button
            >
          </div>
          <div v-else>
            <vs-button
              type="relief"
              color="danger"
              @click="prospeccaoClienteCadastrado"
              class="mt-5"
              :disabled="buttonStatus"
              >Adicionar Para Prospectar</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import selectTipoLigacao from "./../../contrato/selectTipoLigacao"
export default {
  props: {
    segmentos: {
      required: true
    }
  },
  data () {
    return {
      buttonStatus: false,
      novoClienteModal: false,
      clienteNovo: {
        tipo_pessoa: 1
      },
      erros: {},
      clientes: {},
      clienteSelected: null,
      loading: false,
      optionTipo: [
        { text: "CPF", value: 1 },
        { text: "CNPJ", value: 2 }
      ],
    }
  },
  methods: {
    close () { },
    async postClienteNovo () {
      const isValid = await this.validarCPFCNPJ()
      if (!isValid) {
        return
      }
      this.buttonStatus = await true
      this.clienteNovo.id_status = 1
      this.clienteNovo.id_colaborador = await localStorage.getItem("id")
      try {
        const res = await this.$http.post(`cliente`, this.clienteNovo)
        await this.logAssunto(res)
        this.novoClienteModal = false
        this.clienteNovo = {
          tipo_pessoa: 1
        }
        await this.$vs.notify(this.$notify.Success)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$emit("update")
      }
      this.buttonStatus = await false
    },
    async logAssunto (res) {
      try {
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "cadastrar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: `CADASTRO DE LEAD - CLIENTE N° ${res.id}`
        // }
        // await this.$logger(logData)
        await this.$http.post("assuntoCliente", {
          id_assuntos: 1,
          id_cliente: res.id,
          id_estagio: 1,
          status: 2,
          tipo: res.id_tipo_contato,
          id_colaborador: window.localStorage.getItem("id"),
          obs: `CADASTRO DE LEAD - CLIENTE N° ${res.id}`
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async submitForm () {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.postClienteNovo()
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            })
          }
        })
      })
    },
    async findCliente (query) {
      if (query.length > 2) {
        this.loading = true
        try {
          await this.getClienteBySearch(query)
        } catch (err) {
          const error = this.$httpErrors(err)
          this.$vs.notify(error)
        } finally {
          this.loading = false
        }
      }
    },
    async getClienteBySearch (query) {
      try {
        this.clientes = await this.$http.post(`getClienteByQuery`, {
          query: query
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async prospeccaoClienteCadastrado () {
      try {
        const id_colaborador = localStorage.getItem("id")
        this.cliente = await this.$http.put(
          `cliente/` + this.clienteSelected, { id_status: 2, data_proximo_contato: this.$formartData.dataFiltro(), id_colaborador }
        )
        await this.logAssunto(this.cliente)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.clienteSelected = null
        this.novoClienteModal = false
        await this.$emit("update")
      }
    },
    async validarCPFCNPJ () {
      if (this.clienteNovo.cpf_cnpj) {
        this.clienteNovo.cpf_cnpj = await this.$removerMascara.remove(
          this.clienteNovo.cpf_cnpj
        )
        let cpfCnpjValidator = require("cpf-cnpj-validator")
        if (this.clienteNovo.tipo_pessoa == 1) {
          if (!cpfCnpjValidator.cpf.isValid(this.clienteNovo.cpf_cnpj.trim())) {
            this.$vs.notify({
              title: "CPF é inválido",
              text: "Adicione um CPF válido ou NÃO preencha o campo",
              color: "danger"
            })
            return false
          } else {
            return true
          }
        } else {
          if (!cpfCnpjValidator.cnpj.isValid(this.clienteNovo.cpf_cnpj.trim())) {
            this.$vs.notify({
              title: "CNPJ é inválido",
              text: "Adicione um CNPJ válido ou NÃO preencha o campo",
              color: "danger"
            })
            return false
          } else {
            return true
          }
        }
      } else {
        return true
      }
    },
    async selectInteraction (tipo) {
      this.clienteNovo.id_tipo_contato = tipo
    }
  },
  components: { ...components, selectTipoLigacao }
};
</script>
<style lang="scss">
.span-text-validation {
  font-size: 12px !important;
}
</style>
