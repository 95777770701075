<template>
  <div>
    <vx-card style="background-color: #021a33">
      <vs-row vs-type="flex">
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
        >
          <div>
            <h6 style="color: white">
              LEVANTAMENTOS: {{ levantamentos.length }}
            </h6>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
    <VuePerfectScrollbar
      class="scroll-levantamento-lista"
      :settings="settings"
      style="background-color: #ebebeb"
    >
      <div id="levantamento_lista" class="vs-con-loading__container">
        <div v-if="!levantamentos.length > 0">
          <div class="con-colors mt-10 mb-20">
            <ul>
              <span
                class="px-3 pt-2"
                style="color: grey !important; font-weight: 1000 !important"
                ><vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon
              ></span>
            </ul>
          </div>
        </div>
        <vs-list v-for="(data, idx) in levantamentos" v-bind:key="idx">
          <levantamentoCard
            @update="getLevantamentos()"
            :relatorioUrl="relatorioUrl"
            :item="data"
          >
            <template slot="action-buttons">
              <div class="flex flex-wrap"></div>
            </template>
          </levantamentoCard>
        </vs-list>
      </div>
    </VuePerfectScrollbar>
  </div>
</template>
<script>
import levantamentoCard from "./levantamentoCard.vue"
import components from "@/components/default/exports.js"

export default {
  props: {
    filtro: {
    }
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      novoClienteModal: false,
      levantamentos: {},
      relatorioUrl: ""
    }
  },
  methods: {
    async getLevantamentos () {
      await this.$vs.loading({ container: '#levantamento_lista', scale: 0.6 })
      try {
        this.filtro.id_colaborador = localStorage.getItem('id')
        const res = await this.$http.post(`listaLevantamento`, this.filtro)
        this.levantamentos = res.levantamentos
        this.relatorioUrl = res.relatorioUrl
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close('#levantamento_lista > .con-vs-loading')
      }
    },
  },
  components: { ...components, levantamentoCard },
  async mounted () {
    await this.getLevantamentos()
  }
};
</script>

<style>
.scroll-levantamento-lista {
  height: 70vh !important;
}
</style>
