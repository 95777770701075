<template>
  <div>
    <vx-card style="background-color: #000a12">
      <vs-row vs-type="flex">
        <vs-col
          vs-w="10"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
        >
          <div>
            <h6 style="color: white">
              PARA PROSPECTAR: {{ prospeccoes.length }}
            </h6>
          </div>
        </vs-col>
        <vs-col
          vs-w="2"
          vs-type="flex"
          vs-justify="flex-end"
        >
        <vs-button
          icon="get_app"
          @click="exportProspeccoesToExcel()"
          color="green"
          size="small"
          ></vs-button
        >
        </vs-col>
      </vs-row>
    </vx-card>
    <VuePerfectScrollbar
      class="scroll-prospeccao-lista"
      :settings="settings"
      style="background-color: #ebebeb"
    >
      <div
        id="prospeccao_list"
        class="vs-con-loading__container"
        :key="refresh"
      >
        <div v-if="!prospeccoes.length > 0">
          <div class="con-colors mt-10 mb-20">
            <ul>
              <span
                class="px-3 pt-2"
                style="color: grey !important; font-weight: 1000 !important"
                ><vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon
              ></span>
            </ul>
          </div>
        </div>
        <vs-list v-for="(data, idx) in prospeccoes" v-bind:key="idx">
          <!-- <vx-tooltip position="right" color="success" text="Iniciar Prospecção"> -->
          <prospeccao-card
            @updateLembretes="updateLembretes()"
            @update="getProspeccoes()"
            :state="data.id_status"
            :item="data"
            :frasesDeclinio="frasesDeclinio"
          >
            <template slot="action-buttons">
              <div class="flex flex-wrap"></div>
            </template>
          </prospeccao-card>
          <!-- </vx-tooltip> -->
        </vs-list>
      </div>
    </VuePerfectScrollbar>
    <vx-card
      :class="
        $atividades.permissoes.includes(2)
          ? 'newCard mt-2'
          : 'sem-autorizacao mt-2'
      "
    >
      <clienteNovoModal
        class=""
        @update="getProspeccoes"
        :segmentos="segmentos"
      ></clienteNovoModal>
    </vx-card>
  </div>
</template>
<script>
import prospeccaoCard from "./prospeccaoCard.vue"
import components from "@/components/default/exports.js"
import clienteNovoModal from "./clienteNovoModal.vue"
import { data } from "autoprefixer";
//import router from "./../../../../router.js";


export default {
  props: {
    filtro: {
    }
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      novoClienteModal: false,
      clientesProspeccaoExcel: [],
      prospeccoes: {},
      segmentos: {},
      frasesDeclinio: {},
      refresh: 0
    }
  },
  methods: {
    async getFrasesDeclinio () {
      try {
        this.frasesDeclinio = await this.$http.get(`frasesDeclinio`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getSegmentos () {
      try {
        this.segmentos = await this.$http.get(`segmento`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getProspeccoes () {
      await this.$vs.loading({ container: '#prospeccao_list', scale: 0.6 })
      try {
        this.filtro.id_colaborador = localStorage.getItem('id')
        this.prospeccoes = await this.$http.post(`listaProspeccao`, this.filtro)
        this.refresh += 1
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
      await this.$vs.loading.close('#prospeccao_list > .con-vs-loading')
    },
    async updateLembretes () {
      await this.getProspeccoes()
      await this.$emit("updateLembretes")
    },
    async getClientesProspeccao(){
      try{
        this.clientesProspeccaoExcel = await this.$http.post(`listaProspeccao`, this.filtro)
        this.refresh += 1
      }catch(err){
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async exportProspeccoesToExcel(){
      await this.$vs.loading();
      try{

        await this.getClientesProspeccao()

        console.log(await this.getClientesProspeccao())

        import("@/vendor/Export2Excel").then(async (excel) => {
          let headerTitle = [
            "ID",
            "nome",
            "telefone"
          ]

          let valHeader = [
            "id",
            "nome_fantasia",
            "telefone_cliente"
          ]

          const listaProspeccao = this.clientesProspeccaoExcel
          const data = await this.formatJson(valHeader, listaProspeccao)
          const hoje = await this.$formartData.formatar(new Date());
          excel.export_json_to_excel({
            header : headerTitle,
            data,
            filename: `Prospecção_excel_${hoje}`,
            autoWidth: false,
            bookType: "xlsx",
          })
        })
        this.$vs.loading.close();
      }catch(err){
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }finally {
        await this.$vs.loading.close();
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
  components: { ...components, clienteNovoModal, prospeccaoCard },
  async mounted () {
    await this.getProspeccoes()
    await this.getSegmentos()
    await this.getFrasesDeclinio()
  }
};
</script>

<style lang="scss" scoped>
.newCard {
  transition-duration: 50ms;
  background-color: #021a33 !important;
  color: white;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-1px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2 !important;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sem-autorizacao {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
.scroll-prospeccao-lista {
  height: 70vh !important;
}
</style>
