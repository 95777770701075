<template>
  <div :id="'prospeccao_card' + item.id" class="vs-con-loading__container">
    <vx-card :class="cardClass">
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col
              v-if="item.nome_fantasia && item.id"
              class="pb-1"
              vs-w="10"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
            >
              <vs-button
                color="dark"
                class="py-0 px-0 my-0 pr-1"
                size="small"
                type="line"
                @click="openModalCliente(item.id)"
              >
                <span class="nome-cliente">{{
                  item.nome_fantasia ? item.nome_fantasia : "..."
                }}</span>
              </vs-button>
            </vs-col>
            <vs-col
              vs-w="2"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
            >
              <Badge
                :key="reRenderBadge"
                :text="badgeText"
                :color="badgeColor"
              ></Badge>
            </vs-col>
            <div @click="prospectar()">
              <vs-col
                v-if="item.telefone"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm">
                  Telefone:
                  <span class="font-semibold">{{ item.telefone }}</span>
                </span>
              </vs-col>
              <vs-col
                v-if="item.email"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm">
                  E-MAIL:
                  <span class="font-semibold">{{ item.email }}</span>
                </span>
              </vs-col>
              <vs-col
                v-if="item.segmento"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm">
                  Segmento:
                  <span class="font-semibold">{{ item.segmento }}</span>
                </span>
              </vs-col>
              <vs-col
                v-if="item.data_proximo_contato"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm">
                  Data marcada:
                  <span class="font-semibold">
                    {{ item.data_proximo_contato }}
                  </span>
                </span>
              </vs-col>
              <vs-col
                vs-w="12"
                v-if="item.colaborador"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm">
                  Colaborador:
                  <span class="font-semibold">{{ item.colaborador }}</span>
                </span>
              </vs-col>
            </div>
          </div>
        </div>
      </template>
    </vx-card>
    <vs-popup
      v-if="open"
      class
      :title="'Prospectar ' + item.nome_fantasia"
      :active.sync="iniciarProspeccaoModal"
    >
      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <reagendarProspeccaoModal
            @update="reagendarUpdate()"
            @updateLembretes="updateLembretes()"
            :frasesDeclinio="frasesDeclinio"
            :item="item"
          ></reagendarProspeccaoModal>
        </vs-col>
        <vs-col
          vs-w="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <vx-card
            :class="
              $atividades.permissoes.includes(2)
                ? 'prospectar overflow-hidden'
                : 'sem-autorizacao overflow-hidden'
            "
            @click="$atividades.permissoes.includes(2) ? prospectar() : ''"
          >
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="p-5"
                  >
                    <span>
                      <vs-icon
                        icon="phone_forwarded"
                        size="30px"
                        color="success"
                        class="img"
                      ></vs-icon>
                    </span>
                  </vs-col>
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="pb-5"
                  >
                    <span style="font-size: 20px">PROSPECTAR</span>
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </vs-row>
    </vs-popup>
    <component
      v-bind:is="componentCliente"
      :key="reRenderComponentCliente"
      :id_cliente="selectedCliente"
    />
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import ModalEditarCliente from "./../../cliente/editarSemBotao.vue"
import reagendarProspeccaoModal from "./reagendarProspeccaoModal.vue"
import router from "./../../../../router.js"

export default {
  props: {
    item: {
      required: true
    },
    frasesDeclinio: {}
  },
  data () {
    return {
      badgeText: "",
      badgeColor: "",
      reRenderBadge: 1,
      cardClass: "prospeccao-card overflow-hidden",
      optionEmpresa: [
        { text: "EMOPS", value: 4 },
        { text: "E-CONTROL", value: 1 }
      ],
      selectedEmpresa: 1,
      iniciarProspeccaoModal: false,
      open: false,
      selectedCliente: {},
      reRenderComponentCliente: 0,
      componentCliente: ""
    }
  },
  async mounted () {
    await this.badgeUpdate()
  },
  methods: {
    async badgeUpdate () {
      if (this.item.id_status == 8) {
        this.badgeColor = "warning"
        this.badgeText = "RESGATADO"
      }
      else if (this.item.status == 1) {
        this.badgeColor = "danger"
        this.badgeText = "ATRASADO"
      }
      else if (this.item.id_status == 2) {
        this.badgeColor = "warning"
        this.badgeText = "PENDENTE"
      }
      else if (this.item.id_status == 10) {
        this.badgeColor = "danger"
        this.badgeText = "DECLINADO"
      } else if (this.item.id_status == 9) {
        this.badgeColor = "orange"
        this.badgeText = "REAGENDADO"
      } else if (this.item.id_status == 1) {
        this.badgeColor = "success"
        this.badgeText = "NOVO"
      } else if (this.item.id_status == 7) {
        this.badgeColor = "blue"
        this.badgeText = "ANTIGO"
      }
      this.reRenderBadge = this.reRenderBadge + 1
    },
    async prospectar () {
      await this.close()
      await router.push(
        "/prospeccao?emp=" +
        this.selectedEmpresa +
        "&cliente=" +
        this.item.id +
        "&segmento=" +
        this.item.segmento
      )
    },
    async declinarUpdate () {
      await this.close()
      await this.$emit("update")
    },
    async reagendarUpdate () {
      await this.close()
      await this.$emit("update")
    },
    async updateLembretes () {
      await this.close()
      await this.$emit('updateLembretes')
    },
    async close () {
      this.iniciarProspeccaoModal = await false
    },
    async openModalCliente (id_cliente) {
      this.selectedCliente = id_cliente
      this.componentCliente = "ModalEditarCliente"
      this.reRenderComponentCliente++
    }
  },
  components: {
    ...components,
    reagendarProspeccaoModal,
    ModalEditarCliente
  },
};
</script>

<style lang="scss" scoped>
.prospeccao-card {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}

.prospeccao-card-danger {
  transition-duration: 50ms;
  background-color: #c0564b;
  color: white;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #021a33;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.prospeccao-card-orange {
  transition-duration: 50ms;
  background-color: orange;
  color: white;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #021a33;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.prospectar {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.cardCliente {
  background-color: #fbfbfb;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: white;
    cursor: pointer;
    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.con-vs-chip {
  min-height: 15px;
  min-width: 20px !important;
  font-size: 0.8rem;
}
.sem-autorizacao {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
.nome-cliente {
  font-weight: 600;
  font-size: 14px;
}
</style>
