<template>
<div>
  <vx-card style="background-color: #021a33 ;">
    <vs-row vs-type="flex">
    <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
        <div><h6 style="color: white">CONTRATOS: {{contratos.length}} <!--b>/ R$ 3400.00</b--></h6></div>
      </vs-col>
    </vs-row>
  </vx-card>
  <VuePerfectScrollbar class="scroll-contrato-lista"  :settings="settings" style="background-color: #ebebeb;">
  <div id="contrato_lista" class="vs-con-loading__container">
    <div v-if="!contratos.length > 0">
    <div class="con-colors mt-10 mb-20">
        <ul>
          <span class="px-3 pt-2" style="color: grey !important; font-weight: 1000 !important"><vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon></span>
        </ul>
      </div>
    </div>
    <vs-list
      v-for="(data, idx) in contratos"
      v-bind:key="idx"
    >
    <contratoCard :item="data">></contratoCard>
      <!-- <vx-tooltip position="left" color="success" text="Ir para contrato">

      </vx-tooltip> -->
    </vs-list>
    </div>
  </VuePerfectScrollbar>
</div>
</template>
<script>
import contratoCard from"./contratoCard.vue";
import components from "@/components/default/exports.js";

export default {
  props: {
    filtro: {
    }
  },
  data() {
    return {
      settings             : {
        maxScrollbarLength : 60,
        wheelSpeed: 0.8
      },
      contratos: {},
    };
  },
  methods: {
    async getContratos() {
      await this.$vs.loading({ container: '#contrato_lista',scale: 0.6});
      try {
        this.filtro.id_colaborador = localStorage.getItem('id')
        this.contratos = await this.$http.post(`listaContrato`, this.filtro);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close('#contrato_lista > .con-vs-loading');
      }
    },
  },
  components:{ ...components,contratoCard},
  async mounted(){
      await this.getContratos();
  }
  };
</script>

<style>
.scroll-contrato-lista {
  height: 70vh !important;
}
</style>
